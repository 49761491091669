import {get, map, toString, mapValues, toNumber, groupBy, flatten, values, merge, filter} from 'lodash-es';
import {shortName} from '@/store/inspect/selectors';

export const section = item => {
  return {
    mark: toNumber(get(item, 'profileMark', 0)),
    label: toString(get(item, 'label')),
    sections: mapValues(get(item, 'sections'), section),
    questions: mapValues(get(item, 'questions'), (item, code) => ({
      code,
      type: toString(get(item, 'type', 'TEXT')),
      hint: toString(get(item, 'hint', '')),
      label: toString(get(item, 'label')),
      multiple: get(item, 'isMultiple', false),
      changed: !!get(item, 'isChanged', false),
      readonly: !!get(item, 'isReadonly', false),
      required: !!get(item, 'isRequired', false),
      rules: get(item, 'rules'),
      notAdmission: get(item, 'notAdmissionQuestions'),
      options: map(get(item, 'options'), (item) => ({
        text: toString(get(item, 'text')),
        value: toString(get(item, 'value')),
      })),
      values: groupBy(map(get(item, 'values'), (item) => ({
        id: toString(get(item, 'id')),
        type: toString(get(item, 'type', 'TEXT')),
        date: toString(get(item, 'dateExpire')),
        value: toString(get(item, 'value')),
        required: !!get(item, 'isRequired', false),
        readonly: !!get(item, 'isReadonly', false),
        deprecated: !!get(item, 'isDeprecated', false),
      })), 'type'),
    })),
  }
};

// Обратная функция к section
export const sectionPayload = item => ({
  label: toString(get(item, 'label')),
  sections: mapValues(get(item, 'sections'), sectionPayload),
  questions: mapValues(get(item, 'questions'), (item) => ({
    type: toString(get(item, 'type', 'TEXT')),
    label: toString(get(item, 'label')),
    isMultiple: get(item, 'multiple', false),
    isChanged: !!get(item, 'changed', false),
    isReadonly: !!get(item, 'readonly', false),
    options: map(get(item, 'options'), (item) => ({
      text: toString(get(item, 'text')),
      value: toString(get(item, 'value')),
    })),
    values: map(flatten(values(get(item, 'values'))), (item) => ({
      id: toString(get(item, 'id')),
      type: toString(get(item, 'type', 'TEXT')),
      dateExpire: toString(get(item, 'date')),
      value: toString(get(item, 'value')),
      isRequired: !!get(item, 'required', false),
      isReadonly: !!get(item, 'readonly', false),
      isDeprecated: !!get(item, 'deprecated', false),
    })),
  }))
});

export const counterpartyDetail = (result) => {
  return {
    id: toString(get(result, 'id')),
    inn: toString(get(result, 'author.companyInn')),
    name: toString(get(result, 'author.companyName')),
    reviewer: toString(get(result, 'reviewer.id')),
    isBlocked: !!get(result, 'isBlocked', false),
    dateEndBlock: toString(get(result, 'dateEndBlock', false)),
    hasApprovedForm: get(result, 'hasApprovedForm', false),
    status: {
      value: toString(get(result, 'status.xmlId')),
      text: toString(get(result, 'status.name')),
    },
    mark: {
      full: {
        average: toNumber(get(result, 'marks.full.averageProfileMark', 0)),
        general: toNumber(get(result, 'marks.full.generalQualificationMark', 0)),
        integral: toNumber(get(result, 'marks.full.integralComprehensiveMark', 0)),
      },
      simple: {
        average: toNumber(get(result, 'marks.withoutGeneral.averageProfileMark', 0)),
        general: toNumber(get(result, 'marks.withoutGeneral.generalQualificationMark', 0)),
        integral: toNumber(get(result, 'marks.withoutGeneral.integralComprehensiveMark', 0)),
      },
    },
    items: mapValues(get(result, 'items'), section),
  };
};

export const counterpartyList = (result) => ({
  items: map(get(result, 'items'), (item) => ({
    id: toString(get(item, 'id')),
    inn: toString(get(item, 'author.companyInn')),
    name: toString(get(item, 'author.companyName')),
    status: {
      name: toString(get(item, 'lastApprovementStatus.name')),
      value: toString(get(item, 'lastApprovementStatus.xmlId')),
    },
  })),
  count: toNumber(get(result, 'navCount', 0)),
});

export const commentList = (result) => ({
  items: map(result.items, (item) => ({
    id: toString(get(item, 'id')),
    date: toString(get(item, 'dateCreated')),
    author: toString(get(item, 'author.name')),
    parent: toString(get(item, 'parentId') || 0),
    question: {
      id: toString(get(item, 'questionXmlId')),
      name: toString(get(item, 'questionText')),
    },
    message: toString(get(item, 'message')),
    draft: get(item, 'isDraft', false),
    actual: get(item, 'isActual', false),
  })),
  count: result.length,
});

export const registryList = (result) => ({
  items: map(get(result, 'items'), (item) => ({
    general: {
      text: 'Заказчик',
      items: {
        id: {
          text: 'ID',
          value: toString(get(item, 'id')),
        },
        userId: {
          text: 'ID пользователя',
          value: toString(get(item, 'userId')),
        },
        companyName: {
          text: 'Организация',
          value: toString(get(item, 'companyName')),
        },
        email: {
          text: 'Электронная почта',
          value: toString(get(item, 'email')),
        },
        phone: {
          text: 'Номер телефона',
          value: toString(get(item, 'phone')),
        },
        address: {
          text: 'Юр. адрес',
          value: toString(get(item, 'address')),
        },
        goods: {
          text: toString(get(item, 'goods.text')),
          value: toString(get(item, 'goods.value')),
        },
        numberOfEmployees: {
          text: toString(get(item, 'numberOfEmployees.text')),
          value: toString(get(item, 'numberOfEmployees.value')),
        },
        capital: {
          text: toString(get(item, 'capital.text')),
          value: toString(get(item, 'capital.value')),
        },
        annualTurnover: {
          text: toString(get(item, 'annualTurnover.text')),
          value: get(item, 'annualTurnover.value', false) ? 'Да': 'Нет',
        },
        lastThreeYearsProjects: {
          text: toString(get(item, 'lastThreeYearsProjects.text')),
          value: toString(get(item, 'lastThreeYearsProjects.value')),
        },
        experience: {
          text: toString(get(item, 'experience.text')),
          value: toString(get(item, 'experience.value')),
        },
      },
    },
  })),
  count: toNumber(get(result, 'navCount', 0)),
});

export const timelineUser = (item, key) => ({
  code: toString(get(item, key + '.status.xmlId')),
  name: toString(get(item, key + '.status.name')),
  dateCreate: toString(get(item, key + '.date')),
  dateExec: toString(get(item, key + '.dateEnd')),
  reviewers: map(merge(
      filter([get(item, key + '.author')]),
      get(item, key + '.possibleReviewers', [])
    ),
    (reviewer) => ({
      id: get(reviewer, 'id'),
      name: shortName(reviewer),
    })
  ),
})

export const formRegistryList = (result) => ({
  items: map(get(result, 'items'), (item) => ({
    id: toString(get(item, 'id')),
    status: {
      code: toString(get(item, 'status.xmlId')),
      name: toString(get(item, 'status.name')),
    },
    dateSend: toString(get(item, 'dateSend')),
    dateStatus: toString(get(item, 'statusDate')),
    dateStatusEnd: toString(get(item, 'statusEndDate')),
    name: toString(get(item, 'author.companyName')),
    inn: toString(get(item, 'author.companyInn')),
    email: toString(get(item, 'author.email')),
    phone: toString(get(item, 'author.phone')),
    address: toString(get(item, 'author.address')),
    timelineManager: timelineUser(item, 'timelineManager'),
    timelineLawyer: timelineUser(item, 'timelineLawyer'),
    timelineReviewer: timelineUser(item, 'timelineReviewer'),
    timelineApprover: timelineUser(item, 'timelineApprover'),
  })),
  count: toNumber(get(result, 'navCount', 0)),
});

export const approvalHistoryList = (result) => map(get(result, 'items'), (item, index) => {
  return {
    id: index,
    author: shortName(get(item, 'author')),
    status: item.status.name,
    code: item.status.xmlId,
    internalComment: toString(get(item, 'internalComment', '')),
    publicComment: toString(get(item, 'publicComment', '')),
    files: map(get(item, 'files', []), (value) => ({ value })),
    date: toString(get(item, 'dateCreate', '')),
    role: toString(get(item, 'reviewerName', ''))
  };
});

export const userReviewList = (result) => ({
  items: map(result.items, (item) => ({
    id: toString(get(item, 'id')),
    date: toString(get(item, 'dateCreated')),
    author: shortName(get(item, 'author')),
    message: toString(get(item, 'message')),
  })),
  count: result.length,
});
